// utils/getReviewsOffice.js
import axios from 'axios';

const wordToNumber = { 'ONE': 1, 'TWO': 2, 'THREE': 3, 'FOUR': 4, 'FIVE': 5 };
const url = `${process.env.GATSBY_STRAPI_SRC}/google-review-reviews`;
const strapiconfigs = {
  headers: {
    Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
  },
};

const GetReviewsOffice = async () => {
  try {
    const { data } = await axios.get(url, strapiconfigs);

    // Split reviews based on office location
    const dubaiReviews = [];
    const palmJumeirahReviews = [];
    
    data.forEach((review) => {
      const officeId = review.name.split('/')[3]; // Extract office identifier from 'name'
      
      // Map reviews to their respective offices
      if (officeId === '13434365526588654576') { // Dubai Marina office
        dubaiReviews.push(review);
      } else if (officeId === '3793031195649172864') { // Palm Jumeirah office
        palmJumeirahReviews.push(review);
      }
    });

    // Now, you can calculate the reviewCount and averageRating for each office separately
    const calculateRating = (reviews) => {
      const reviewCount = reviews.length;
      let sumOfScores = 0;

      reviews.forEach((review) => {
        const reviewStringValue = review.starRating;
        sumOfScores += wordToNumber[reviewStringValue];
      });

      const averageRating = reviewCount ? Math.round((sumOfScores / reviewCount) * 10) / 10 : 0;
      return { reviewCount, averageRating };
    };

    const dubaiRating = calculateRating(dubaiReviews);
    const palmJumeirahRating = calculateRating(palmJumeirahReviews);

    return {
        Dubai: dubaiRating,
        Palm_Jumeirah: palmJumeirahRating,
    };
  } catch (error) {
    console.error("Failed to fetch reviews:", error);
    return { dubai: { reviewCount: 0, averageRating: 0 }, palmJumeirah: { reviewCount: 0, averageRating: 0 } };
  }
};

export default GetReviewsOffice;
